import {Link} from "react-router-dom";

import icon_quotatiom from '../../resources/icons/icon_quotation.png';
import icon_conversation from '../../resources/icons/icon_conversation.png';
import icon_approval from '../../resources/icons/icon_approval.png';

const SimpleIcon = ({image, title, path, selected = false}) => {
    return (
        <div>
            <Link to={path}>
                <img style={{maxHeight: "84px"}} src={image} alt={""}/>
                <strong style={{display: "inline-block", width: "100%", color: selected ? "black" : "white"}}>{title}</strong>
            </Link>
        </div>
    )
}

const Conversation = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"panel-row"}>
                    <div>
                        <div className={"panel-row-flex f3"}>
                            <SimpleIcon
                                image={icon_quotatiom}
                                path="/about/procedure/quotation"
                                title="Offerte"/>
                            <SimpleIcon
                                image={icon_conversation}
                                path="/about/procedure/conversation"
                                title="Bespreking"
                                selected={true}/>
                            <SimpleIcon
                                image={icon_approval}
                                path="/about/procedure/approval"
                                title="Goedkeuring"/>
                        </div>
                    </div>
                </div>
                {/* Company Profile */}
                <div className={"container"}>
                    <div>
                        <h1>Bespreking</h1>
                        <p style={{textAlign: "left"}}>
                            Het project wordt besproken samen met opdrachtgever, samen komen wij zo tot een perfecte oplossing.
                            Alles details worden doorgenomen en ook eventuele risico’s worden zo uitgesloten.
                        </p>
                    </div>
                    <p style={{paddingBottom: "15px"}}>
                        <Link to={"/contact"} className={"button_contact div_center"}>Neem contact met ons op</Link>
                    </p>
                </div>
            </div>
        </>
    )
};

export default Conversation;