import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import icon_search from '../resources/icons/icon_search.svg';

const SearchBar = ({inputValue, setInputValue, onChange = () => {}, onSubmit = () => {}}) => {
    onChange();
    const submit = (e) => {
        const {keyCode} = e;
        if (keyCode === 13)
            onSubmit(e);
    }

    return (
        <div className={"container"}>
            <div>
                <div className={"panel-row-flex black items"} style={{flexWrap: "wrap"}}>
                    <div style={{width: "100%", display: "inline-block"}}>
                        <input
                            className="searchBar"
                            placeholder="Zoeken naar..."
                            onKeyDown={(e) => {submit(e)}} value={inputValue} onChange={(e) => {
                            setInputValue(e.target.value)
                            onChange(e, e.target.value)}}/>
                        <button className="searchSubmit">
                            <img src={icon_search} alt="" onClick={onSubmit}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProductItem = ({product}) => {
    const SpecItem = ({name}) => {
        return (
            <li key={name}>
                {name}
            </li>
        )
    }

    return (
        <div>
            <Link className="product" to={product.name}>
                <h1>{product.name ?? ""}</h1>
                <div style={{display: "block", height: "189px"}}>
                    {
                        product.thumbnail ?
                            <img src={`${process.env.REACT_APP_API_ENDPOINT}${product.thumbnail}`} style={{maxHeight: "100%", maxWidth: "100%"}} alt={""} loading='lazy'/>
                            : <></>
                    }
                </div>
                <p>
                    {product.nettPrice ? <span style={{fontSize: "1.5rem",color: "var(--main-background-color)"}}><b>€{product.nettPrice.toString().replaceAll(".", ",")}</b></span> : <span style={{fontSize: "2rem"}}></span>}
                    <br/>
                    <strong>{product.info}</strong>
                    <br/>
                    <br/>
                    <strong>{product.extra}</strong>
                    <br/>
                    <br/>
                </p>
                <ul>
                    {product.specs && product.specs.map((spec, index) =>
                        index < 3 && <SpecItem name={spec} key={`${spec}-${product._id}`}/>)}
                </ul>
            </Link>
        </div>
    )
}

const Occasions = () => {
    const [searchValue, setSearchValue] = useState("");
    const [products, setProducts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await fetch(`${process.env.REACT_APP_API_ENDPOINT}/product/all`, {
                method: 'GET'
            })
                .then((response) => response.json())
                .then((data) => setProducts(data))
                .catch((err) => {
                    console.log(err.message);
                });
        } fetchData();
    }, []);

    const searchProducts = (value) => {
        const url =
            value !== '' ? `${process.env.REACT_APP_API_ENDPOINT}/product/search/${value}` :
                `${process.env.REACT_APP_API_ENDPOINT}/product/all`;

        fetch(url, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => setProducts(data))
            .catch((err) => {
                console.log(err.message);
            });
    }


    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Occasions</h1>
                </div>
                <SearchBar inputValue={searchValue} setInputValue={setSearchValue} onSubmit={() => {
                    searchProducts(searchValue);
                }}/>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex black f4 items"} style={{flexWrap: "wrap", justifyContent: "unset"}}>
                            {
                                products && products.status === "OK" && products.data.map((product) =>
                                    <ProductItem product={product} key={product._id}/>
                                )
                            }
                            {
                                products && products.status === "OK" && products.data.length === 0 && <div>Geen zoekresultaten gevonden</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Occasions;