import {useState} from "react";

const Contact = () => {
    const [inputs, setInputs] = useState({company: "", contact: "", email: "", telephone: "", request: ""});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(inputs);

        fetch('https://rs-machinebouw.nl/contact', {
            method: 'POST',
            body: JSON.stringify({
                data: inputs
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                alert("Uw verzoek is ingediend!");
            });
    }

    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Contact</h1>
                </div>
                <iframe title={"location"}
                    src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d78626.562097824!2d5.9849349!3d51.9871943!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x47c7a27d19d3816b%3A0x4217f47f85b6efa0!2sRS%20Machinebouw%2C%20Uitmaat%2010%2C%206987%20ER%20Giesbeek!3m2!1d51.987109499999995!2d6.0551468!5e0!3m2!1snl!2snl!4v1627463811496!5m2!1snl!2snl"
                    height="400" style={{border: 0, width: "100%"}} allowFullScreen="" loading="lazy"/>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex black f2"} style={{flexWrap: "wrap"}}>
                            <div>
                                <h1 style={{textAlign: "left"}}>Contact</h1>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Machinebouw</strong><br/>
                                    <br/>
                                    Uitmaat 10b<br/>
                                    6987 ER Giesbeek<br/>
                                    +31 (0)6-12797751<br/>
                                    <a href="mailto:sales@rs-machinebouw.nl">info@rs-machinebouw.nl</a>
                                </p>
                            </div>
                            <div>
                                <h1 style={{textAlign: "left"}}>Contact formulier</h1>
                                <form className={"description form"} onSubmit={handleSubmit}>
                                    <div className={"contact"}>
                                        <label>Bedrijfsnaam</label>
                                        <input name={"company"} type={"text"} onChange={handleChange}/>
                                        <label>Contactpersoon <sup>*</sup></label>
                                        <input name={"contact"} type={"text"} required onChange={handleChange}/>
                                        <label>E-mail <sup>*</sup></label>
                                        <input name={"email"} type={"email"} required onChange={handleChange}/>
                                        <label>Telefoonnummer</label>
                                        <input name={"telephone"} type={"tel"} onChange={handleChange}/>
                                        <label>Vraag of verzoek <sup>*</sup></label>
                                        <textarea name={"request"} required onChange={handleChange}/>
                                    </div>
                                    <p style={{paddingBottom: "15px"}}>
                                        <button type="submit" style={{border: "none"}} className={"button_contact div_center"}>Verstuur</button>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Contact;