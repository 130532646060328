import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import './styles/default.css';

import Body from "./pages/Body";

import Main from "./pages/Main";
import Contact from "./pages/Contact";
import Quotation from "./pages/about/Quoation";
import Conversation from "./pages/about/Conversation";
import Approval from "./pages/about/Approval";
import Occasions from "./pages/Occasions";
import Product from "./pages/occasions/Product";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Body/>}>
                    <Route index path="*" element={<Main/>}/>
                    <Route path="occasions" element={<Occasions/>}/>
                    <Route path="occasions/:name" element={<Product/>}/>
                    <Route path="about/procedure/quotation" element={<Quotation/>}/>
                    <Route path="about/procedure/conversation" element={<Conversation/>}/>
                    <Route path="about/procedure/approval" element={<Approval/>}/>
                    <Route path="contact" element={<Contact/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();