import {Link, useParams} from "react-router-dom";
import useFetch from "../../custom_hooks/useFetch";
import {useEffect} from "react";

import UNKNOWN from '../../resources/file_types/unknown-document-svgrepo-com.svg';
import EXCEL from '../../resources/file_types/excel-document-svgrepo-com.svg';
import CSV from '../../resources/file_types/csv-document-svgrepo-com.svg';
import EXE from '../../resources/file_types/exe-document-svgrepo-com.svg';
import GDOC from '../../resources/file_types/gdoc-document-svgrepo-com.svg';
import IMG from '../../resources/file_types/image-document-svgrepo-com.svg';
import PDF from '../../resources/file_types/pdf-document-svgrepo-com.svg';
import PPT from '../../resources/file_types/ppt-document-svgrepo-com.svg';
import TXT from '../../resources/file_types/txt-document-svgrepo-com.svg';
import VID from '../../resources/file_types/video-document-svgrepo-com.svg';
import WORD from '../../resources/file_types/word-document-svgrepo-com.svg';
import XML from '../../resources/file_types/xml-document-svgrepo-com.svg';
import ZIP from '../../resources/file_types/zip-document-svgrepo-com.svg';

import "../../styles/stylesheet_slide-show.css";

let slideIndex = 1;

function createSlides(urlImages) {
    let slideShow = document.getElementById("divSlideShowContainer");
    slideShow.innerHTML = "";
    // Create a loop for adding the fullsized images.
    for (let x = 0; x < urlImages.length; x++)
    {
        slideShow.insertAdjacentHTML("beforeend",
            "<div class=\"divSlides\">\n" +
            "   <div class=\"divNumbers\"></div>\n" +
            "   <img src=\"" + urlImages[x] + "\" alt='' loading='lazy'>" +
            "</div>");
    }

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    function currentSlide(n) {
        showSlides(slideIndex = n);
    }
    // Add the navigation buttons.
    if (urlImages.length > 1) slideShow.insertAdjacentHTML("beforeend", "<a class=\"aPrev\" id='prev'>❮</a><a class=\"aNext\" id='next'>❯</a>");
    // Create the container for the columns.
    let rowContainer = document.createElement("div");
    rowContainer.className = "row";
    // Create a loop and add the column to the row.
    for (let i = 0; i < urlImages.length; i++) {
        let container = document.createElement("div");
        container.className = "column";
        let imgDemo = document.createElement("img");
        imgDemo.className = "imgDemo cursor";
        imgDemo.src = urlImages[i];
        // imgDemo.style.width = "100%";
        imgDemo.onclick = () => currentSlide(i+1);
        container.append(imgDemo);
        rowContainer.insertAdjacentElement("beforeend", container);
    }
    // Add row container to the slideShow.
    slideShow.insertAdjacentElement("beforeend", rowContainer);
    // Show the slide.
    showSlides(slideIndex);

    if (urlImages.length > 1) {
        document.getElementById("prev").onclick = () => plusSlides(-1);
        document.getElementById("next").onclick = () => plusSlides(1);
    }
}

function showSlides(n) {
    let x;
    let slides = document.getElementsByClassName("divSlides");
    let dots = document.getElementsByClassName("imgDemo");
    if (n > slides.length) slideIndex = 1;
    if (n < 1) slideIndex = slides.length;
    for (x = 0; x < slides.length; x++) slides[x].style.display = "none";
    for (x = 0; x < dots.length; x++)  dots[x].className = dots[x].className.replace(" active", "");
    slides[slideIndex-1].style.display = "block";
    dots[slideIndex-1].className += " active";
}

const CustomInfoTable = ({title, items}) => {
    const InfoTableRow = ({name}) => {
        return (
            <tr>
                <td>{name}</td>
            </tr>
        )
    }

    return (
        items && items.length > 0 &&
        <>
            <h1>{title}</h1>
            <table className={"specTable"}>
                <tbody>
                {items.map((option) =>
                    <InfoTableRow key={option} name={option}/>)}
                </tbody>
            </table>
        </>
    )
}

const FileTypeIconComponent = ({data}) => {
    let fileTypeIcon;
    const
        fileSplit = data.split("."),
        fileType = fileSplit[fileSplit.length -1].toLowerCase();

    const
        nameSplit0 = data.split("/"),
        nameSplit1 = nameSplit0[nameSplit0.length-1],
        dashSplit0 = nameSplit1.split("-"),
        fileName   = dashSplit0[dashSplit0.length-1];

    switch (fileType) {
        case "gdoc":
            fileTypeIcon = GDOC;
            break;
        case "zip":
            fileTypeIcon = ZIP;
            break;
        case "csv":
            fileTypeIcon = CSV;
            break;
        case "pdf":
            fileTypeIcon = PDF;
            break;
        case "xlsx":
            fileTypeIcon = EXCEL;
            break;
        case "exe":
            fileTypeIcon = EXE;
            break;
        case "ppt":
            fileTypeIcon = PPT;
            break;
        case "txt":
            fileTypeIcon = TXT;
            break;
        case "mov":
        case "mp4":
            fileTypeIcon = VID;
            break;
        case "docx":
            fileTypeIcon = WORD;
            break;
        case "xml":
            fileTypeIcon = XML;
            break;
        case "png":
        case "jpg":
        case "svg":
            fileTypeIcon = IMG;
            break;
        default:
            fileTypeIcon = UNKNOWN;
    }

    return (
        <tr>
            <td style={{width: "25px"}}><img style={{height: "25px"}} src={fileTypeIcon} alt={""}/></td>
            <td><a href={data} download>{fileName}</a></td>
        </tr>
    )
}

const Product = () => {
    const {name} = useParams();
    const [product] = useFetch(`${process.env.REACT_APP_API_ENDPOINT}/product/name/${name}`);

    useEffect(() => {
        if (product?.data && product.data.thumbnail) {
            const images = [`${process.env.REACT_APP_API_ENDPOINT}${product.data.thumbnail}`];
            if (product.data.images.length > 0) {
                for (let i = 0; i < product.data.images.length; i++) {
                    images.push(`${process.env.REACT_APP_API_ENDPOINT}${product.data.images[i]}`)
                }
            }
            console.log(images);
            createSlides(images);
        }
    });

    return (
        <div className={"content"}>
            <div className={"container"}>
                <div>
                    {
                        product && product.status === "OK" && product.data && (
                            <>
                                <div className={"panel-row-flex black f2"} style={{flexWrap: "wrap"}}>
                                    <div>
                                        <h1>{name}</h1>
                                        <div className="rowDivMachine divSlideShow divScaler2" id="divSlideShowContainer"></div>
                                    </div>
                                    <div className={"description"}>
                                        <img src={product.data.brand?.logo ?? ""} alt={""} style={{height: "100px", width: "auto"}}/>
                                        <p>
                                            <strong>{product.data.info}</strong>
                                            <br/>
                                            <br/>
                                            {product.data.description.split("\n").map(function(item, idx) {
                                                return (
                                                    <span key={idx}>{item}<br/></span>
                                                )
                                            })}
                                        </p>
                                        {
                                            product.data.nettPrice &&
                                            <>
                                                <hr style={{height: "1px", backgroundColor: "var(--main-background-color)", border: 0}}/>
                                                <div style={{textAlign: "left", fontWeight: "bolder", color: "var(--main-background-color)", display: "grid", gridTemplateRows: "auto auto"}}>
                                                    <span style={{fontSize: "2rem"}}>€{product.data.nettPrice.toString().replaceAll(".", ",")}</span>
                                                    <label style={{fontSize: "1rem", color: "gray"}}>excl. BTW</label>
                                                </div>
                                            </>
                                        }
                                        <a href={
                                            `mailto:sales@rs-machinebouw.nl?subject=${product.data._id}&body=Geachte RS Machinebouw,%0D%0A%0D%0AIk schrijf u om een bestelling te plaatsen voor het volgende product:%0D%0A%0D%0AProduct: ${product.data.name} - aantal: 1x`} style={{padding: "0 20px 40x", minWidth: "170px"
                                        }} className={"button_contact div_center"}>Bestellen</a>
                                    </div>
                                </div>
                                <div className={"description"}>
                                    <h1>Specificaties</h1>
                                    <table className={"specTable"}>
                                        <tbody>
                                        <tr>
                                            <td>Merk</td>
                                            <td>{product.data.brand?.name ?? "geen merk"}</td>
                                        </tr>
                                        {product.data.specs && product.data.specs.map((specs) => (
                                            <tr key={specs}>
                                                {
                                                    <td>{specs.split(": ")[0]}</td>
                                                }
                                                {
                                                    <td>{specs.split(": ")[1]}</td>
                                                }
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <CustomInfoTable items={product.data.options} title="Opties"/>
                                    <CustomInfoTable items={product.data.technical} title="Technische kenmerken"/>
                                    <CustomInfoTable items={product.data.applications} title="Toepassingen"/>
                                    {
                                        product.data.downloads && product.data.downloads.length > 0 && (
                                        <>
                                                <h1>Downloads</h1>
                                                <table className={"specTable"}>
                                                    <tbody>
                                                    {product.data.downloads.map((options) => (
                                                        <FileTypeIconComponent key={options} data={options}/>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                    {
                        product && !product.data && <h1>Excuses, occasion '{name}' kan niet gevonden worden!</h1>
                    }
                </div>
            </div>
        </div>
    )
}

export default Product;