import {Link} from "react-router-dom";

import icon_industrial from '../resources/icons/icon_industrial.png';
import icon_paper_industry from '../resources/icons/icon_paper_industry.png';
import icon_robot_arm from '../resources/icons/icon_robot_arm.png';
import icon_transportband from '../resources/icons/icon_transportband.png';
import icon_pc_with_code from '../resources/icons/icon_pc_with_code.png';
import icon_design from '../resources/icons/icon_design.png';
import icon_transportation from '../resources/icons/icon_transportation.png';

import icon_revision from '../resources/icons/icon_revision.png';
import icon_rebuild from '../resources/icons/icon_rebuild.png';
import icon_expand from '../resources/icons/icon_expand.png';
import icon_innovation_and_backing from '../resources/icons/icon_innovation_and_backing.png';
import icon_engineering from '../resources/icons/icon_engineering.png';
import icon_assembly from '../resources/icons/icon_assembly.png';
import icon_plc from '../resources/icons/icon_plc.png';
import icon_move from '../resources/icons/icon_move.png';
import icon_monitoring from '../resources/icons/icon_monitoring.png';
import icon_pump from '../resources/icons/icon_pump.png';

const SimpleIcon = ({image, title}) => {
    return (
        <div>
            <img style={{maxHeight: "84px"}} src={image} alt={""}/>
            <strong>{title}</strong>
        </div>
    )
}

const CompanyProfileHighlight = ({title, description}) => {
    return (
        <div className={"container_white"}>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    )
}

const Service = ({image, description}) => {
    return (
        <tr>
            <td><img src={image} alt={""}/></td>
            <td style={{paddingTop: "8px"}}>{description}</td>
        </tr>
    )
}

const Main = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"panel-row"}>
                    <div>
                        <div className={"panel-row-flex f7"}>
                            <SimpleIcon
                                image={icon_industrial}
                                title="Industrie & machinebouw"/>
                            <SimpleIcon
                                image={icon_robot_arm}
                                title="Robotisering"/>
                            <SimpleIcon
                                image={icon_transportband}
                                title="Transportbanden"/>
                            <SimpleIcon
                                image={icon_pc_with_code}
                                title="Industriële  Besturingen"/>
                            <SimpleIcon
                                image={icon_transportation}
                                title="Machine verhuizing"/>
                            <SimpleIcon
                                image={icon_design}
                                title="Engineering & 3D ontwerp"/>
                            <SimpleIcon
                                image={icon_paper_industry}
                                title="Grafische & papier industrie"/>
                        </div>
                    </div>
                </div>
                {/* Company Profile */}
                <div className={"container container_blue"}>
                    <div>
                        <h1>Ons bedrijfsprofiel</h1>
                        <p>
                            <strong>RS Machinebouw</strong> is een dynamisch bedrijf uit Nederland. Wij zijn continue opzoek naar uitdagingen / opportunity’s. Vanuit onze locatie in Giesbeek werkt een team ervaren machinebouwers. Wij bieden u deskundig advies en klant gerichte oplossingen met onze expertise, creativiteit en persoonlijke aandacht.
                        </p>
                        <div className={"panel-row-flex f3"}>
                            <CompanyProfileHighlight
                                title="Revisie van A tot Z"
                                description={<><b>RS-Machinebouw</b> inspecteert en reviseert alle machines in uw productieomgeving. Tijdens een productiestop voeren we reparaties en modificaties snel uit, zodat u snel weer verder kunt draaien.</>}/>
                            <CompanyProfileHighlight
                                title="Hergebruiken"
                                description={<>Vaak zijn bestaande machines te gebruiken voor een geheel andere toepassing in een andere industrie. <b>RS-Machinebouw</b> is gespecialiseerd in het modificeren van machines.</>}/>

                            <CompanyProfileHighlight
                                title="Productielijnen uitbereiden"
                                description={<>Voldoen uw machines niet meer aan vraag uit de markt? <b>RS-Machinebouw</b> zorgt met slimme aanpassingen dat u meer uit uw machinepark haalt!</>}/>
                        </div>
                    </div>
                    <p style={{paddingBottom: "15px"}}>
                        <Link to={"/contact"} className={"button_contact div_center"}>Neem contact met ons op</Link>
                    </p>
                </div>
                {/* Services */}
                <div className={"container container_white"}>
                    <div>
                        <h1>Welke diensten verlenen wij?</h1>
                        <table className={"images"}>
                            <tbody>
                                <Service
                                    image={icon_revision}
                                    description={<><b>RS-Machinebouw</b> inspecteert en reviseert alle machines in uw productieomgeving. Tijdens een productiestop voeren we reparaties en modificaties snel uit, zodat u snel weer verder kunt draaien.</>}/>
                                <Service
                                    image={icon_rebuild}
                                    description={<>Vaak zijn bestaande machines te gebruiken voor een geheel andere toepassing in een andere industrie. <b>RS-Machinebouw</b> is gespecialiseerd in het modificeren van machines.</>}/>
                                <Service
                                    image={icon_expand}
                                    description={<>Voldoen uw machines niet meer aan vraag uit de markt? <b>RS-Machinebouw</b> zorgt met slimme aanpassingen dat u meer uit uw machinepark haalt!</>}/>
                                <Service
                                    image={icon_innovation_and_backing}
                                    description={<>Er zijn veel subsidiemogelijkheden voor innovatieve bedrijven. Maar het lukt maar niet om dat innovatieplan op te zetten. <b>RS-Machinebouw</b> maakt uw plan helemaal in orde zodat u kunt profiteren van de subsidiemogelijkheden.</>}/>
                                <Service
                                    image={icon_engineering}
                                    description="Bij revisies en optimalisaties van uw productie ontwerpen en fabriceren we unieke onderdelen. We maken graag simpele oplossingen."/>
                                <Service
                                    image={icon_assembly}
                                    description="We zorgen bij revisies en onderhoud voor juiste montage en soepele inbedrijfstelling. Met ons team of in samenwerking met uw mensen."/>
                                <Service
                                    image={icon_plc}
                                    description="Het programmeren van PLC’s is de laatste stap in het finetunen van uw productielijn. We schrijven in de meest gangbare programmeertalen."/>
                                <Service
                                    image={icon_move}
                                    description={<>Uw nieuwe fabriekshal is prachtig geworden. Maar het vervoer van uw gespecialiseerde machinepark vraagt om gedegen kennis en ervaring. <b>RS-Machinebouw</b> zorgt ervoor dat alles na de verhuizing weer op rolletjes loopt.</>}/>
                                <Service
                                    image={icon_monitoring}
                                    description={<><b>Robe Automatisering b.v.</b> maakt met haar Robe Smart Industry 4.0 concept een machine of productielijn geschikt voor Industry 4.0.</>}/>
                                <Service
                                    image={icon_pump}
                                    description={<><b>RS Pompen</b> is gespecialiseerd in leveren van pompen, pomp systemen, mixers en appendages. Vanuit onze locatie in Giesbeek werkt een team ervaren pomp specialisten. Wij bieden u deskundig advies en klant gerichte oplossingen met onze expertise, creativiteit en persoonlijke aandacht.</>}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Main;