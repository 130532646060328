import {Link} from "react-router-dom";

import icon_quotatiom from '../../resources/icons/icon_quotation.png';
import icon_conversation from '../../resources/icons/icon_conversation.png';
import icon_approval from '../../resources/icons/icon_approval.png';

const SimpleIcon = ({image, title, path, selected = false}) => {
    return (
        <div>
            <Link to={path}>
                <img style={{maxHeight: "84px"}} src={image} alt={""}/>
                <strong style={{display: "inline-block", width: "100%", color: selected ? "black" : "white"}}>{title}</strong>
            </Link>
        </div>
    )
}

const Quoation = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"panel-row"}>
                    <div>
                        <div className={"panel-row-flex f3"}>
                            <SimpleIcon
                                image={icon_quotatiom}
                                path="/about/procedure/quotation"
                                selected={true}
                                title="Offerte"/>
                            <SimpleIcon
                                image={icon_conversation}
                                path="/about/procedure/conversation"
                                title="Bespreking"/>
                            <SimpleIcon
                                image={icon_approval}
                                path="/about/procedure/approval"
                                title="Goedkeuring"/>
                        </div>
                    </div>
                </div>
                {/* Company Profile */}
                <div className={"container"}>
                    <div>
                        <h1>Offerte</h1>
                        <p style={{textAlign: "left"}}>
                            In het offertestadium wordt er een pakket van eisen samengesteld door middel van overleg en advies.
                            Deze eisen worden bekeken door <b>RS-Machinebouw b.v.</b> op haalbaarheid en functionaliteit.
                            Wanneer de eisen duidelijk en haalbaar zijn wordt er een offerte gemaakt van de aangevraagde machine.
                            Bij deze offerte wordt er een schematische tekening gemaakt ter indicatie.
                            In deze tekeningen staan verwoord wat het voorstelt, hoe het werkt en hoe groot het systeem of de machine wordt.
                        </p>
                    </div>
                    <p style={{paddingBottom: "15px"}}>
                        <Link to={"/contact"} className={"button_contact div_center"}>Neem contact met ons op</Link>
                    </p>
                </div>
            </div>
        </>
    )
};

export default Quoation;