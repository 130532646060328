import '../styles/footer.css';
import '../styles/default.css';
const Footer = () => {
    return (
        <footer>
            <p>
                <b>RS Machinebouw</b><br/>
                <br/>
                Uitmaat 10b<br/>
                6987 ER  Giesbeek<br/>
                +31 (0)6-12797751<br/>
                <a href="mailto:info@rs-machinebouw.nl">info@rs-machinebouw.nl</a>
                <br/>
            </p>
        </footer>
    )
};

export default Footer;