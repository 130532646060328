import '../styles/header.css';
import '../styles/default.css';
import logo from '../resources/logo_rs-machinebouw.png';
import headerImg from '../resources/rs-video-poster.png';
import burger from '../resources/icon_burger.svg';
import {Link} from "react-router-dom";
const Header = () => {
    return (
        <header>
            <div>
                <div>
                    <Link to={"/"}>
                        <img className={"headerLogo"} src={logo} alt={""}/>
                    </Link>
                    <img className={"burgerIcon"} src={burger} alt={""}/>
                </div>
                <div className={"headerMenu"} style={{maxWidth: "none"}}>
                    <ul>
                        <li><Link to={""}>HOME</Link></li>
                        <li><Link to={"/occasions"}>OCCASIONS</Link></li>
                        <li><Link to={"/about/procedure/quotation"}>OVER ONS</Link></li>
                        <li><Link to={"/contact"}>CONTACT</Link></li>
                        <li><Link reloadDocument to={"/login"}>LOGIN</Link></li>
                    </ul>
                </div>
            </div>
            <div className={"headerImageContainer"}>
                <div className={"headerInfoContainer"}>
                    <div style={{width: "96%", maxWidth: "var(--main-max-width)", margin: "0 auto 0 auto"}}>
                        <div className={"headerInfo"}><strong>RS-MACHINEBOUW</strong> IS UW SPECIALIST IN HET ONTWERPEN, BOUWEN EN LEVEREN VAN MACHINES
                            <Link to={"/contact"} className={"button_contact"}>Neem contact met ons op</Link>
                        </div>
                    </div>
                </div>
                <img className={"headerImage"} src={headerImg} alt={""}/>
            </div>
        </header>
    )
};

export default Header;